import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  contact('#contact-form--footer')
  rebox('.instanode-headstart-thumbimage a')
  toggleBelowFold()
  smoothScroll('ul.second-level-sub-navigation a[href*="/#"]', {offset: 0})
  smoothScroll('.alphabet-nav--wrapper div a[href^="#"]', {offset: 50})
})
